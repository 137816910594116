.invest-main{
   height: 100vh;
   width: 90%;
   margin: 0 auto;
}
.invest{
    text-align: center;
    font-size: 16px;
    padding: 16px;
}
.see-pro{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    margin: 0 auto;
}
.see-product{
    padding: 10px;
    font-size: 17px;
}
@media all and (max-width: 768px) {
    .invest-main{
        height: 100%;
        width: 95%;
        margin: 0 auto;
     }
}
