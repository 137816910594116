.Offer-Main{
    margin: 0 auto;
    width: 93%;
    background-color: #ff6c001f;
}
.offer-deal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 23px;
    margin-right: 30px;
}
.offer-deal h2{
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: -10px;
    color: #aa1818;
    font-size: 13px;
}
.offer-deal h2:hover{
    color: #cf1616;
}
.Offer-Container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;
    padding-top: 6px;
    padding-bottom: 6px; 
}
.Offer-Product{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90%;
    width: 18%;
    background-color: rgb(251, 251, 251);
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border: 3px solid #fff;
    position: relative;
}
.Offer-Product:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: .4s;
    border: 3px solid #ee6d05;
}
.Offer-Product img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
}
.sale-out{
position:absolute;
top: 16px;
right: 17px;
border: 1px solid #312f2fd1;
padding: 0px 8px 0px 8px;
border-radius: 10px;
color: #312f2fd1;
}
.Offer-Product h2:hover{
    text-decoration: underline;
}
.price-flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 4px;
}
.price-line{
    text-decoration: line-through;
    font-size: 16px;
    color: #918080;
}
.price{
    margin-left: 10px;
    font-size: 20px;
    color: #e05d0f;;
}
.Offer-Product h2{
    font-size: 16px;
    line-height: 19px;
    padding: 4px;
    color: #474444;
    cursor: pointer;
}

.offer-addtocart{
    color: white;
    margin-top: 5px;
    background: linear-gradient(90deg, rgb(236, 116, 116) 0%, rgb(217, 97, 35) 100%);
    border: 1px solid #f57224;
    border-radius: 3px;
    padding: 4px;
    width: 100%;
    font-size: 13px;
}

.offer-buynow{
    color: white;
    margin-top: 5px;
    background-color: #c9151bc2;
    border: 1px solid #c9151b7d;
    border-radius: 3px;
    padding: 4px;
    width: 100%;
    font-size: 13px;
}
.offer-buynow:hover{
    background-color: #8e0005;
    border-color: #8e0005;
   cursor: pointer;
}
.offer-addtocart:hover{
    background: #d0611e;
    cursor: pointer;
}
.Offer-Pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
@media all and (max-width: 768px) {
    .Offer-Product {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 90%;
        width: 45%;
        padding: 10px;
        border-radius: 5px;
        margin: 10px;
    }
    .Offer-Main{
        margin: 0 auto;
        width: 100%;
    }
    .Offer-Product{
    height: 90%;
    width: 47%;
    padding: 7px;
    margin: 4px;
    }
    .Offer-Product h2{
        font-size: 12px;
        margin: 0px;
        padding-bottom: 5px;
    }
    .offer-deal{
    margin-left: 9px;
    margin-right: 12px;
    }
    .Offer-Pagination{
        padding: 0px;
    }
    .offer-addtocart{
        font-size: 12px; 
    }
    .offer-buynow{
        font-size: 12px;
    }
  }