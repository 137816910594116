.Notification-Main{
    width: 100%;
    background-color: black;
    color: aliceblue;
    padding: 10px;
    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .Notification-Main{
      font-size: 15px;
    }
}