@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fasthand&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 0px 6px 0px 0px;
  color: var(--textColor);
  background: linear-gradient(
    90deg,
    rgba(244, 207, 207, 1) 0%,
    rgba(214, 102, 43, 1) 100%
  );
}
.logoMain{
  width: 250px;
  height: 55px;
}
.h-logo {
  height: 100%;
  transition: 0.4s;
  cursor: pointer;
  font-size: 32px;
  color: #1f1f1fbf;
  font-family: "Kalam", cursive;
  text-align: center;
  padding: 6px;
  margin-left: 5px;
}
/* .h-logo:hover {
  width: 204px;
  padding: 3px;
  transition: 0.4s;
} */
header h2:hover {
  padding: 0px 3px;
  transition: 0.2s;
}

nav a {
  margin: 0 1rem;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

nav a:hover {
  color: #c9151b;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}
.search-main {
  position: relative;
}
@media only screen and (max-width: 1024px) {
  header .nav-btn {
    display: inline-block;
    opacity: 1;
    color: #fff;
  }

  header nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: linear-gradient(
      90deg,
      rgba(187, 79, 0, 0.88) 0%,
      rgb(214, 102, 43) 100%
    );
    transition: 0.7s;
    transform: translateX(200vh);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 2;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 18px;
    color: #fff;
  }
}
