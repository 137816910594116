.footer{
    background: linear-gradient(90deg, rgb(226 157 157) 0%, rgb(190 147 124) 100%);
    margin-top: 10px;
    padding: 15px;
    color: #fff;
}
.deDesign-and-Developeds{
text-align: center;
}
.deDesign-and-Developeds a{
color: #8a4b4b;   
 }

 @media only screen and (max-width: 1024px) {
    .footer{
        background: linear-gradient(43deg, #8e0005a6 0%, #f57224cc 100%);
    }
    .deDesign-and-Developeds{
        font-size: 15px;
        } 
    .deDesign-and-Developeds a{
        color: #000000ad;
        cursor: pointer;
        } 
 }