.n-main-product {
  margin: 0 auto;
  display: block;
  width: 95%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.n-image-and-buynow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.show-offer {
  display: none;
}
.show-offer h4 {
  display: none;
}
.show-offer p {
  display: none;
}
.n-product-photo {
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #5958580a;
}
.n-product-photo img {
  width: 80%;
  height: 80%;
  margin: 20px;
  border-radius: 5px;
}
.n-code-and-stock-on-image {
  display: none;
}
.n-code-and-stock-on-image {
  display: none;
}
.n-Product-code-image p {
}
.n-product-buy-now {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 52%;
  margin: 20px;
}
.n-product-buy-now h2 {
  font-size: 20px;
}
.n-detail-price {
  display: flex;
}
.n-product-price-line-through {
  text-decoration: line-through;
  padding: 10px;
  font-size: 22px;
  color: #1d1e1f8c;
}
.n-product-price- {
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #0088cc;
}
.n-product-code {
  font-size: 17px;
  line-height: 0;
  background: #41b468;
  color: #fff;
  display: inline-block;
  padding: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  margin-bottom: 10px;
}

.n-color-variation {
  display: flex;
  flex-direction: column;
}
.n-color-image {
  display: flex;
  flex-wrap: wrap;
}

.n-color-image img {
  width: 13%;
  height: 13%;
  border: 3px solid #fff;
  border-radius: 7px;
  margin: 2px;
  cursor: pointer;
}

.n-color-image img:hover {
  border: 3px solid #ffb2b2;
}
.n-variation-size {
  width: 100%;
  margin-bottom: 5px;
}
.n-select-size {
  width: 24%;
  padding: 3px 6px 3px 3px;
  border: 2px solid #ffb2b2;
}
.n-Quantity-plus- {
  margin-bottom: 14px;
}
.n-Quantity-plus- button {
  width: 31px;
  padding: 3px;
  cursor: pointer;
}
.n-Quantity-Input {
  width: 45px;
  border: none;
  height: 30px;
  text-align: center;
  font-weight: bold;
  outline: none;
  background-color: #ffb2b2;
}
.n-order-button-product {
  color: white;
  margin-top: 5px;
  background-color: #c9151bc9;
  border: 1px solid #c9151b;
  border-radius: 3px;
  padding: 4px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
}
.n-cart-button-product {
  color: white;
  margin-top: 5px;
  background-color: #f57224;
  border: 1px solid #f57224;
  border-radius: 3px;
  padding: 4px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
}

.n-order-button-product:hover {
  background-color: #8e0005;
  border-color: #8e0005;
  cursor: pointer;
}
.n-cart-button-product:hover {
  background: #d0611e;
  cursor: pointer;
}
.n-call-product {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #008000e0;
  padding: 5px 16px 5px 16px;
  font-size: 23px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
}
.n-product-d-icon {
  margin-right: 5px;
}
.n-product-delivery {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 2px dotted #ccc;
  padding: 9px;
  font-size: 19px;
  margin-bottom: 10px;
}
.n-product-description {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
}
.n-Product-Desc-in {
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.n-des-faq {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.n-des {
  width: 50%;
  border: 1px solid #5958581d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.n-des h2 {
  background-color: #fff;
  width: 100%;
  text-align: center;
  color: #555454;
  font-size: 22px;
}
.n-faq {
  width: 50%;
  border: 1px solid #5958581d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.n-faq h2 {
  background-color: #fff;
  width: 100%;
  text-align: center;
  color: #555454;
  font-size: 22px;
}
.n-faq p {
  padding: 10px;
}
.n-faq-button {
  color: white;
  margin-top: 5px;
  background-color: #f57224;
  border: 1px solid #f57224;
  border-radius: 3px;
  padding: 4px;
  width: 24%;
  font-size: 16px;
  margin-bottom: 20px;
}
.n-faq-button:hover {
  background: #d0611e;
  cursor: pointer;
}
.n-question-in-loop {
  color: #0088cc;
}
.n-answer-in-loop-display {
  display: block;
}
.n-answer-in-loop-none {
  display: none;
}
.n-mobile-order-card {
  display: none;
}
.n-mobile-product {
  display: none;
}
.count {
  display: none;
}
.Messanger-A {
  text-decoration: none;
}
@media all and (max-width: 768px) {
  .n-product-desktop {
    display: none;
  }
  .n-mobile-product {
    display: block;
    border: 3px solid #ff5f5f;
  }
  .MessangerButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .Messanger-A {
    width: 100%;
    text-decoration: none;
  }
  .n-mobile-product img {
    width: 99%;
    height: 99%;
    margin: 2px;
    border-radius: 5px;
    position: relative;
    border-radius: 0px 0px 0px 0px;
  }
  .show-offer {
    display: block;
    background-color: #782323;
    margin: 9px;
    text-align: center;
    color: #fff;
    padding: 1px;
    border-radius: 10px;
    overflow: hidden;
  }
  .show-offer h4 {
    display: block;
    background-color: #b22222c4;
  }
  .show-offer p {
    display: block;
    padding: 6px;
  }
  .n-product-photo {
    border: none;
  }
  .n-main-product {
    width: 100%;
  }
  .n-image-and-buynow {
    flex-direction: column;
  }
  .n-product-photo {
    width: 100%;
    position: relative;
  }
  .ship-time {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 9px;
  }
  .n-code-and-stock-on-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 2px;
    background: linear-gradient(
      342deg,
      rgba(79, 40, 40, 0.3) 0%,
      rgb(84, 54, 39) 100%
    );
    width: 99%;
    z-index: 1;
  }
  .n-Product-code-image {
    width: 53%;
    padding: 3px;
    margin: 3px;
    color: #fff;
    text-align: center;
  }

  .n-Product-code-image p {
    font-size: 15px;
  }
  .n-Product-price-on-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000087;
  }
  .n-product-price-line-through-on-image {
    text-decoration: line-through;
    font-size: 17px;
    color: #e4e4e4;
    margin-right: 10px;
  }
  .n-color-image {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  .n-product-price-on-image {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin: 0px;
    padding: 0px;
  }
  .n-color-image img {
    width: 18%;
  }
  .n-mobile-order-card {
    width: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #312f2f2b 0%, rgb(144 0 0 / 10%) 100%);
    margin: -6px auto;
    padding: 5px;
    color: #fff;
    margin-bottom: 2px;
  }
  .n-mobile-order {
    background-color: #c9151bc9;
    border: 1px solid #c9151b;
    color: #fff;
    padding: 3px 25px 3px 25px;
    border-radius: 4px;
    font-size: 17px;
    margin: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
  }
  .n-mobile-cart {
    background-color: #f57224;
    border: 1px solid #f57224;
    color: #fff;
    padding: 3px 14px 3px 14px;
    border-radius: 4px;
    font-size: 17px;
    margin: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
  }
  .n-select-size {
    width: 55%;
  }
  .n-Quantity-Input {
    width: 100px;
  }
  .n-Quantity-plus- button {
    width: 51px;
    padding: 2px;
    cursor: pointer;
    font-size: 14px;
  }
  .n-Quantity-plus- {
    margin-bottom: 14px;
    margin-top: 8px;
  }
  .n-variation-size {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 9px;
  }
  .n-product-code {
    display: none;
  }
  .n-detail-price {
    display: none;
  }
  .n-call-product {
    color: #fff;
    width: 100%;
  }

  .n-product-buy-now {
    display: none;
  }
  .n-product-description {
    padding: 4px;
    margin: 14px auto;
  }
  .n-des-faq {
    flex-direction: column;
  }
  .n-des {
    width: 100%;
  }
  .n-faq {
    width: 100%;
    display: none;
  }
  .n-faq-button {
    width: 80%;
  }
}
