.CartTop{
    width: 100%;
    background-color: #ffffff3d;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 1s;
    color: #ffffff;
    font-size: 24px;
    background-color: rgb(156 89 202 / 91%);
}

.CartTopa{
    width: 100%;
    background-color: rgba(157, 89, 202, 0.956);
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 1s;
    position: fixed;
    color: #ffffff;
    font-size: 24px;
    z-index: 5;
}
.cart-top-checkout-button{
    background-color: #c9151bc9;
    border: 1px solid #c9151b;
    padding: 7px;
    margin: 9px;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
}
.cart-top-checkout-button:hover{
    background-color: #8e0005;
    border-color: #8e0005;
    cursor: pointer;
}
.check-cart-icon{
    margin-top: 5px;
}

button.shake{
    animation: shake 1s infinite;
    background-color: #ff821e03;
    border: 1px solid #ffffff;
    padding: 2px 10px 2px 10px;
    margin: 9px;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
.shake-{
    background-color: #ff821e03;
    border: 1px solid #ffffff;
    padding: 2px 10px 2px 10px;
    margin: 9px;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
@keyframes shake {
  0%  { transform: translate(2px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -2px) rotate(-2deg); }
  20% { transform: translate(-3px, 0px)  rotate(3deg);  }
  30% { transform: translate(0px, 2px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 2px)  rotate(-1deg); }
  60% { transform: translate(-3px, 1px)  rotate(0deg);  }
  70% { transform: translate(2px, 1px)   rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(4deg);  }
  90% { transform: translate(2px, 2px)   rotate(0deg);  }
  100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}
.cart-item-span{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    left: 19px;
    border-radius: 50%;
    font-size: 14px;
    background-color: #c9151b;
    height: 20px;
    width: 20px;
}
@media only screen and (max-width: 1024px) {
    .top-cart-container{
        width: 100%;
        
    }
}




