.modalBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(11, 11, 11, 0.059);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: first baseline;
    z-index: 2;
    top: 14%;
  }
  .flicker {
    margin: 1rem auto;
    animation: neon 1s ease infinite;
    background-color: #000;
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
}
  @keyframes neon {
    0%,
    100% {
        text-shadow: 0 0 10px #ef00e3a8, 0 0 20px #ef00e3a8, 0 0 20px #ef00e3a8, 0 0 20px #ef00e3a8, 0 0 2px #fed128, 2px 2px 2px #806914;
        color: #f5efcb;
    }
    50% {
        text-shadow: 0 0 2px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b, 0 0 2px #800e0b, 4px 4px 2px #40340a;
        color: #eda0d3;
    }
}

  #cancelBtn{
    background-color: #ed143dcf;
  }
  .modalContainer {
    width: 37%;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
  }
  .modalContainer img{
  margin-bottom: 10px;
  }
  .x-button{
position: absolute;
right: 10px;
top: 10px;
  }
  .modalProductImg{
    margin: 0 auto;
  }
  .CmFile{
    width: 19%;
  }
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  .category-upload{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title h1{
    color: #000;
  }

  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer-modal {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer-modal button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }
  .count{
    width: 38%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00000038;
    color: #fff;
    border-radius: 6px;
    padding: -1px;
    font-size: 17px;
    height: 28px;
    overflow: hidden;
    margin: 0 auto;
}
.count-neg{
    padding: 16px;
    background: #8665ff9c;
    cursor: pointer;

}
.count-pos{
  background: #8665ff9c;
  cursor: pointer;
  padding: 16px;
}
  @media all and (max-width: 768px) {
    .modalContainer {
      background-color: #fff;
      border-radius: 1px;
      box-shadow: 0 5px 15px #000;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 20px;
      width: 95%;
      border: 4px solid #f66800e5;
    }
  }