.mainCheckout{
    display: block;
    width: 96%;
    margin: 0 auto;
    padding: 30px;
    height: 120vh;
}
.deliveryLocation{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 6px;
    margin-bottom: 12px;
    border: 2px solid rgb(255 136 3 / 42%);
    border-radius: 3px;
}
.checkout-container{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc61;
}
.checkout-left{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}
.checkout-right{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px

}
.mobile-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    border-radius: 8px;
    background-color: #fff;
    transition: all .3s cubic-bezier(.4,0,.2,1);
    box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%);
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.mobile-section p{
    font-size: 17px;
color: #d83030c2;
margin-bottom: 10px;
border: 1px solid rgba(255, 136, 3, 0.42);
padding: 15px;
}

.mobile-number-checkout{
    width: 100%;
}
.mobile-checkout{
    display: block;
    border-radius: 5px;
    background: rgba(109,124,144,.1);
    width: 100%;
    margin: 16px 0;
    padding: 8px 12px;
    text-align: left;
    border: 2px solid rgb(255 136 3 / 42%);
    transition: all .3s cubic-bezier(.4,0,.2,1);
    line-height: 1.8em;
    letter-spacing: .5px;
    font-weight: 700;
    color: #20292f;
    outline: none;
}
.privateNote{
    display: block;
    border-radius: 5px;
    background: rgba(109,124,144,.1);
    width: 100%;
    margin: 16px 0;
    padding: 8px 12px;
    text-align: left;
    border: 2px solid rgb(255 136 3 / 42%);
    transition: all .3s cubic-bezier(.4,0,.2,1);
    line-height: 1.8em;
    letter-spacing: .5px;
    font-weight: 700;
    color: #20292f;
    outline: none;
}
.call-button-checkout{
    width: 100%;
    border: 0;
    outline: 0;
    border-radius: 9999px;
    padding: 10px 0;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    background: linear-gradient(90deg, rgb(221 117 0) 0%, rgb(202 88 28) 100%);
    background-color: #67b5ff;
    cursor: pointer;
    margin-bottom: 20px;
}
.privateNote{
    width: 100%;
    border: 2px solid rgb(255 136 3 / 42%);
    padding: 10px;
    outline: none;
    transition: all .3s cubic-bezier(.4,0,.2,1);
    font-size: 17px;
}
.checkout-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: aliceblue;
    padding: 17px;
}
.Total-Cart{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    
    padding: 17px;
}
.total-item-{
    width: 50%;
    background-color: aliceblue;
    margin: 10px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    font-size: 22px;
}
.card-data-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 17px 10px;
    border-radius: 8px;
    background-color: #fff;
    transition: all .3s cubic-bezier(.4,0,.2,1);
    box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%);
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    margin-top: 20px;
margin-bottom: 20px;
  }

.cart-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    border: 1px solid #ccc;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 5px;
}
.cart-item img{
    width: 60px;
    height: 60px;
    border-radius: 6px;
}
.cart-item h2{
    font-size: 14px;
}
.cart-input-container{
    width: 21%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-button{
    width: 21%;
}
.cart-input{
    width: 33%;
    text-align: center;
}
.cart-remove{
    background-color: #c9151bc9;
    border: 1px solid #c9151b;
    border-radius: 8px;
    padding: 4px;
    color: #fff;
}
.no-cart-data{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 300px;
}
.thankyou{
    display: block;
    margin: 0 auto;
    width: 91%;
    border: 1px solid #cccccc61;
    height: 100vh;
    padding: 30px;
}
.thankyou-in{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc61;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    border-radius: 8px;
    background-color: #fff;
    transition: all .3s cubic-bezier(.4,0,.2,1);
    box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%);
    margin: 10px;
}
.thank-icon{
    font-size: 42px;
    color: green;
    margin: 20px;
}
.thank-h2{
    color: #101010c7;
    padding: 10px;
}
.thank-p{}
.thank-button{
    width: 26%;
    border: 0;
    outline: 0;
    border-radius: 9999px;
    padding: 8px 0;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    background: linear-gradient(90deg, rgb(221 117 0) 0%, rgb(202 88 28) 100%);
    background-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    background-color: #67b5ff;
    cursor: pointer;
    margin: 22px;
}
@media all and (max-width: 768px) {
    .mainCheckout{
        width: 100%;
        padding: 0px;
        height: 100%;
    }
    .checkout-container{
      flex-direction: column;
    }
    .checkout-left {
        width: 100%;
        padding: 5px;
      }
      .checkout-right {
        width: 100%;
        padding: 0px;
      }
      .card-data-section{
        display: flex;
        flex-direction: column;
        width: 95%;
        padding: 11px 10px;
        border-radius: 8px;
        background-color: #fff;
        transition: all .3s cubic-bezier(.4,0,.2,1);
        box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%);
        justify-content: center;
        align-items: center;
        margin-right: 0px;
        margin-bottom: 33px;
      }
      .cart-item{
        padding: 2px;
        justify-content: space-around;
        margin-bottom: 5px;
    }
    .cart-item img{
        width: 45px;
        height: 45px;
    }
    .mobile-checkout{
        padding: 13px 12px;
        font-size: 18px;
    }
    .mobile-section{
        padding: 0px 6px;
    }
    .call-button-checkout{
        font-size: 20px;
        padding: 11px 0px;
        border-radius: 6px
    }
    .mobile-section p{
        font-size: 21px;
        text-align: center;
        margin-top: 10px;
   }
    .cart-item h2{
        font-size: 11px;
    }
    .cart-input-container{
       
    }
    .cart-button{
        width: 33%;
    }
    .cart-input{
        width: 32%;
    }
    .cart-remove{
        padding: 2px;
        font-size: 11px;
    }
    .thankyou{
        width: 100%;
        height: 100%;
    }
    .thankyou-in{
        margin: 0px;
        padding: 27px 9px;
    }
    .thank-h2{
        padding: 8px;
        font-size: 23px;
    }
    .thank-p{
        font-size: 20px;
        padding: 20px;
        text-align: center;
    }
    .thank-button{
        width: 55%;
    }
}