.main-product {
  margin: 0 auto;
  display: block;
  width: 95%;
}

.image-and-buynow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.product-photo {
  width: 60%;
  border: 2px solid #5958580a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-photo img {
  width: 80%;
  height: 80%;
  margin: 20px;
  border-radius: 5px;
}
.code-and-stock-on-image {
  display: none;
}
.code-and-stock-on-image {
  display: none;
}
.Product-code-image p {
}
.product-buy-now {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 40%;
  margin: 20px;
}
.product-buy-now h2 {
  font-size: 20px;
}
.detail-price {
  display: flex;
}
.product-price-line-through {
  text-decoration: line-through;
  padding: 10px;
  font-size: 22px;
  color: #1d1e1f8c;
}
.product-price- {
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #0088cc;
}
.product-code {
  font-size: 17px;
  line-height: 0;
  background: #41b468;
  color: #fff;
  display: inline-block;
  padding: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
  margin-bottom: 10px;
}
.Message-button {
  display: flex;
  align-items: center;
  background-color: #1877f2;
  color: #fff;
  padding: 7px;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 12px;
}
.MessangerButton-p {
  display: "flex";
  align-items: center;
  background-color: #1877f2;
  color: "#fff";
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 12px;
}
.color-variation {
  display: flex;
  flex-direction: column;
}
.color-image {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.color-image img {
  width: 13%;
  height: 13%;
  border: 3px solid #fff;
  border-radius: 7px;
  margin: 2px;
  cursor: pointer;
}

.color-image img:hover {
  border: 3px solid #ffb2b2;
}
.variation-size {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.choose-size {
  padding: 10px;
  border: 2px solid #e6dddd;
  margin: 5px;
  cursor: pointer;
}
.choose-size:hover {
  border: 2px solid #ffb2b2;
}
.choose-size-active {
  border: 2px solid #ffb2b2;
}
.select-size {
  width: 24%;
  padding: 3px 6px 3px 3px;
  border: 2px solid #ffb2b2;
}
.Quantity-plus- {
  margin-bottom: 14px;
  margin-top: 14px;
}
.Quantity-plus- button {
  width: 31px;
  padding: 3px;
  cursor: pointer;
}
.Quantity-Input {
  width: 45px;
  border: none;
  height: 30px;
  text-align: center;
  font-weight: bold;
  outline: none;
  background-color: #ffb2b2;
}
.order-button-product {
  color: white;
  margin-top: 5px;
  background-color: #c9151bc9;
  border: 1px solid #c9151b;
  border-radius: 3px;
  padding: 4px;
  width: 100%;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}
.cart-button-product {
  color: white;
  margin-top: 5px;
  background-color: #f57224;
  border: 1px solid #f57224;
  border-radius: 3px;
  padding: 4px;
  width: 100%;
  font-size: 22px;
  margin-bottom: 19px;
}

.order-button-product:hover {
  background-color: #8e0005;
  border-color: #8e0005;
  cursor: pointer;
}
.cart-button-product:hover {
  background: #d0611e;
  cursor: pointer;
}
.call-product {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #008000e0;
  padding: 5px 16px 5px 16px;
  font-size: 23px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
}
.product-d-icon {
  margin-right: 5px;
}
.product-delivery {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 2px dotted #ccc;
  padding: 9px;
  font-size: 19px;
  margin-bottom: 10px;
}
.product-description {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
}
.Product-Desc-in {
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.des-faq {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.des {
  width: 50%;
  border: 1px solid #5958581d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.des h2 {
  background-color: #fff;
  width: 100%;
  text-align: center;
  color: #555454;
  font-size: 22px;
}
.faq {
  width: 50%;
  border: 1px solid #5958581d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.faq h2 {
  background-color: #fff;
  width: 100%;
  text-align: center;
  color: #555454;
  font-size: 22px;
}
.faq p {
  padding: 10px;
}
.faq-button {
  color: white;
  margin-top: 5px;
  background-color: #f57224;
  border: 1px solid #f57224;
  border-radius: 3px;
  padding: 4px;
  width: 24%;
  font-size: 16px;
  margin-bottom: 20px;
}
.faq-button:hover {
  background: #d0611e;
  cursor: pointer;
}
.question-in-loop {
  color: #0088cc;
}
.answer-in-loop-display {
  display: block;
}
.answer-in-loop-none {
  display: none;
}
@media all and (max-width: 768px) {
  .image-and-buynow {
    flex-direction: column;
  }
  .product-photo {
    width: 100%;
    position: relative;
  }
  .product-photo img {
    width: 97%;
    height: 97%;
    margin: 6px;
    border-radius: 5px;
    position: relative;
    border: 1px solid #49414133;
    border-radius: 0px 0px 5px 0px;
  }
  .code-and-stock-on-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 6px;
    background: linear-gradient(
      342deg,
      rgba(79, 40, 40, 0.3) 0%,
      rgb(84, 54, 39) 100%
    );
    width: 96%;
    border-radius: 0px 0px 6px 6px;
  }
  .Product-code-image {
    width: 53%;
    padding: 3px;
    margin: 3px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
  }
  .Product-code-image p {
    font-size: 15px;
  }
  .Message-button {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .Message-button-a {
    width: 100%;
  }
  .Product-price-on-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000087;
  }
  .product-price-line-through-on-image {
    text-decoration: line-through;
    font-size: 17px;
    color: #e4e4e4;
    margin-right: 10px;
  }
  .color-image {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  .product-price-on-image {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin: 0px;
    padding: 0px;
  }
  .color-image img {
    width: 23%;
  }
  .select-size {
    width: 55%;
  }
  .Quantity-Input {
    width: 100px;
  }
  .Quantity-plus- button {
    width: 51px;
    padding: 2px;
    cursor: pointer;
    font-size: 14px;
  }
  .Quantity-plus- {
    width: 58%;
    margin: 5px auto;
  }
  .Quantity-plus- p {
    text-align: center;
  }
  .variation-size {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 9px;
  }
  .product-code {
    display: none;
  }
  .detail-price {
    display: none;
  }
  .call-product {
    color: #fff;
    width: 100%;
  }
  .product-buy-now h2 {
    font-size: 14px;
    line-height: 25px;
    color: #0a0a0abf;
  }
  .product-buy-now {
    width: 99%;
    margin: 2px;
  }
  .order-by-phone {
    text-align: center;
    font-size: 14px;
    width: 100%;
  }
  .product-description {
    width: 100%;
    margin: 0 auto;
    border: 0px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 4px;
    padding: 0px;
  }
  .des-faq {
    flex-direction: column;
  }
  .des {
    width: 100%;
  }
  .faq {
    width: 100%;
  }
  .faq-button {
    width: 80%;
  }
}
