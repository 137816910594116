.category-main{
  margin: 0 auto;
  padding: 20px;
  margin: 20px 0px 0px 0px;
}
.no-category{
  width: 90%;
    text-align: center;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 26px;
    border: 1px solid #ccc;
    margin-top: 20px;
}
.category-wrap{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 14px;
}
.category-box-h3{
  padding: 4px;
  width: 12%;
  text-align: center;
  border-radius: 7px;
  font-size: 11px;
  color: #4f4747;
  border: 2px solid #fff;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: .4s;
}
.category-box-h3 h3{
margin-top: 6px;
}
.category-box-h3:hover{
  padding: 10px;
  border-radius: 7px;
  transition: 80ms;
  cursor: pointer;
  border:2px solid #ee6d05bf;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: .4s;
}
.category-box{
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 32px;
  font-weight: 200;
  padding: 3px;
}
.category-box img{
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.category-loading{
  margin: 0 auto;
  padding: 20px;
  margin: 20px 0px 0px 0px;
  
}
.category-loading-in{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 14px;
  border: 1px solid #0000000f;
  padding: 30px;
}
@media all and (max-width: 768px) {
  .category-main{
    padding: 0px;
    margin: 22px 0px 0px 0px;
  }

  .category-box-h3 {
    width: 30%;
    padding: 2px;
    margin: 5px;
  }
  .category-box{
    border-radius: 8px;
    background-color: #fff;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    align-items: center;
    display: flex;
    font-size: 32px;
    font-weight: 200;
    padding: 0px;
  }
  .category-wrap{
    margin: 5px;
  }
  .category-box-h3 h3{
    font-size: 13px;
    margin: 0px;
  }
}